@import url('https://use.typekit.net/xeo2vqe.css');

.about-page-container {
    display: flex;
    flex-direction: column;
    font-family: 'source-sans-pro', sans-serif;
    justify-content: flex-end;
    margin: 50px auto;
    align-items: center;
}

.photos-banner {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.8%;
    margin: 0 auto;
    max-width: 550px;
}

.photos-img {
    flex: 1;
    max-width: 23.6%;
}

.about-heading {
    color: #316cf6;
    font-family: inherit;
    font-size: 40px;
    margin-top: 40px;
    margin-bottom: 30px;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
}

.about-subheading {
    color: #316cf6;
    font-family: inherit;
    font-size: 22px;
    margin-top: 20px;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
}

.about-text {
    margin-bottom: 15px;
    width: 70%;
}

.about-text p {
    padding: 20px;
    color: #444;
    font-size: 18px;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -5px;
}

.about-sign-off {
    display: flex;
    text-align: left;
    justify-content: flex-end;
    margin-top: 40px;
    color: #444;
}

@media screen and (max-width: 768px) {
    .about-page-container {
        margin: 30px auto;
    }
    .photos-banner {
        padding: 0 15px;
    }
    .photos-img {
    }
    .about-heading {
        font-size: 35px;
        margin-bottom: 20px;
    }
    .about-subheading {
    }
    .about-text {
        width: 95%;
    }
    .about-text p {
        font-size: 16px;
    }
    .about-sign-off {
        font-size: 16px;
        justify-content: center;
    }
}

.contact-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 50px 0;
}

.contact-page-container h2 {
    color: #316cf6;
    font-size: 36px;
}

.contact-page-container p {
    color: #444;
    font-size: 18px;
    margin-top: 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    padding-top: 35px;
    gap: 3vh;
}

.contact-form div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.contact-form div label {
    color: #316cf6;
    width: 60%;
    text-align: start;
    margin-bottom: 5px;
    line-height: 22px;
}

.contact-form div input {
    width: 60%;
    height: 7vh;
    background-color: white;
    border: 2px solid #316cf6;
    border-radius: 10px;
    color: #444;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 15px;
}

.contact-form div select {
    width: 60%;
    height: 7vh;
    background-color: white;
    border: 2px solid #316cf6;
    border-radius: 10px;
    color: #444;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    box-sizing: border-box;
    padding: 0 15px;
    background: url('../../assets/blue-dropdown-arrow.png') no-repeat right white;
    background-size: 18px;
    background-position: right 4% bottom 50%;
    -webkit-appearance: none;
}

.contact-form div textarea {
    width: 60%;
    height: 12vh;
    background-color: white;
    border: 2px solid #316cf6;
    border-radius: 10px;
    color: #444;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    box-sizing: border-box;
    padding: 10px;
    resize: none;
}

.contact-form button {
    margin-top: 1vh;
    background-color: #316cf6;
    color: white;
    width: 25%;
    height: 50px;
    font-size: 0.8rem;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 40px 10px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    width: 50%;
}

.modal-content p {
    width: 90%;
}

.modal-content button {
    width: 40%;
    margin-top: 30px;
    background-color: #316cf6;
    color: #fff;
}

.modal-logo {
    height: 120px;
}

.contact-form button:hover {
    background-color: #1d5ef5;
}

.contact-form button:active {
    background-color: #0a45ce;
}

.modal-content button:hover {
    background-color: #1d5ef5;
}

.modal-content button:active {
    background-color: #0a45ce;
}

@media screen and (max-width: 768px) {
    .contact-form {
        width: 90%;
    }
    .contact-page-container h2 {
        font-size: 30px;
    }
    .contact-page-container p {
        font-size: 15px;
    }
    .contact-form div label,
    .contact-form div input,
    .contact-form div select,
    .contact-form div textarea {
        width: 90%;
        font-size: 0.85rem;
    }
    .contact-form button {
        width: 60%;
        height: 6vh;
        font-size: 0.85rem;
    }
    .modal-content {
        width: 75%;
    }
    .modal-content p {
        width: 90%;
        font-size: 14px;
    }
    .modal-content button {
        font-size: 12px;
        width: 70%;
    }
}

.privacy-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin: 20px 8%;
    font-family: 'Lato', sans-serif;
}

.privacy-banner {
    width: 100%;
    background-color: #316cf6;
    color: white;
    text-align: center;
    padding: 40px 0px;
    margin-bottom: 60px;
    border-radius: 10px;
}

.privacy-banner h1 {
    margin-bottom: 10px;
    font-size: 36px;
    font-family: 'Lato', sans-serif;
}

.privacy-banner h2 {
    margin-bottom: 25px;
    font-size: 24px;
    font-family: 'Lato', sans-serif;
}

.privacy-banner p {
    font-size: 18px;
    color: #eee;
    font-family: 'Lato', sans-serif;
}

.privacy-page-container {
    display: flex;
    position: relative;
    margin-bottom: 30px;
    justify-content: center;
}

.privacy-table-of-contents {
    flex-direction: column;
    position: sticky;
    top: 50px;
    margin-right: 3%;
    height: 350px;
    font-family: 'Lato', sans-serif;
    text-align: left;
}

.privacy-table-of-contents h2 {
    color: #222;
    margin-bottom: 15px;
    font-size: 22px;
    font-family: 'Lato', sans-serif;
}

.privacy-table-of-contents ul {
    color: #444;
    list-style: none;
    padding: 0;
}

.privacy-table-of-contents li {
    margin-bottom: 10px;
}

.privacy-table-of-contents li a {
    text-decoration: none;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    color: #222;
}

.privacy-table-of-contents li.active a {
    font-weight: bold;
    color: #007bff;
}

.privacy-content {
    flex: 1;
    padding-bottom: 20px;
}

.privacy-section {
    margin-bottom: 15px 0;
    padding: 15px 20px;
    text-align: left;
}

.privacy-section-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.privacy-section h2 {
    color: #333;
    margin-bottom: 15px;
    font-size: 22px;
    font-family: 'Lato', sans-serif;
}

.privacy-section p {
    color: #555;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
}

.privacy-section span {
    color: #444;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.privacy-questions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #316cf6;
    padding: 40px 0px;
    margin-bottom: 15px;
    border-radius: 10px;
    gap: 20px;
    align-items: center;
}

.privacy-questions-container h2 {
    font-size: 24px;
    color: white;
    width: 80%;
}

.privacy-questions-container img {
    width: 250px;
}

@media screen and (max-width: 768px) {
    .privacy-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin: 20px 5%;
    }
    .privacy-banner {
        margin-bottom: 50px;
        width: '100%';
        padding: 25px 0px;
    }
    .privacy-banner h1 {
        margin-bottom: 10px;
        font-size: 26px;
    }

    .privacy-banner h2 {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .privacy-banner p {
        font-size: 15px;
        color: #eee;
    }
    .privacy-page-container {
        margin-bottom: 0;
    }
    .privacy-table-of-contents {
        display: none;
    }
    .privacy-content {
        padding-bottom: 0px;
    }
    .privacy-section {
        margin-bottom: 45px;
        padding: 0 10px;
        text-align: center;
    }
    .privacy-section h2 {
        color: #333;
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 120%;
    }

    .privacy-section p {
        color: #555;
        font-size: 16px;
    }
    .privacy-questions-container {
        margin-bottom: 5px;
        padding: 25px 0px;
    }

    .privacy-questions-container h2 {
        font-size: 17px;
        color: white;
        width: 80%;
    }
    .privacy-questions-container img {
        width: 150px;
    }
}

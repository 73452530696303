/* Footer.css */

.footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #316cf6;
    color: #fff;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 40px;
    gap: 30px;
}

.footer-logo-contact {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.footer-logo img {
    width: 100px; /* Adjust the logo size as needed */
    margin-right: 10px;
    cursor: pointer;
}

.footer-divider {
    height: 30px;
    width: 1px;
    background-color: white;
    margin: 0 10px;
}

.footer-email-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.footer-email-container p {
    text-align: start;
    margin: 0;
    color: white;
    font-size: 0.9rem;
}

.footer-email-container a {
    color: #f9e82b;
}

.footer-email {
    text-decoration: underline;
}

.footer-socials {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 20px; /* Adjust gap between icons */
    align-items: center;
    justify-content: center;
}

.footer-socials a {
    color: #fff; /* Initial icon color */
    font-size: 26px; /* Icon size */
    transition: color 0.3s ease; /* Smooth transition for color change */
}

.footer-socials a:hover,
.footer-socials a:focus {
    color: #ccc; /* Color change on hover/focus */
}

.footer-links {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-around;
}

.links-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.link-col-title {
    margin-bottom: 10px;
    font-size: 16px;
    color: #eee;
}

.link {
    color: #fff;
    font-size: 12px;
}

.link:hover {
    color: #ddd;
}

@media screen and (max-width: 768px) {
    .footer-container {
        flex-direction: column;
    }
    .footer-links {
        width: 100%;
        justify-content: space-evenly;
    }
}

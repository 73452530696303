.landing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*******************/
.section-banner {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: 3vh;
    margin-bottom: 10vh;
    /* background-size: contain; Adjust the background size as needed */
}

.introduction-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 50%;
    margin-bottom: 5vh;
}

.banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: scaleUp 1.5s ease-in-out 0.4s forwards;
    transform: scale(0.85);
}

.tab-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    max-height: 35px;
    padding: 0px;
    width: 55%;
    border: 1.5px solid #444;
    border-radius: 15px;
    overflow: hidden;
}

.tab-button {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #f8f8f8;
    border-radius: 0px;
    margin: 0px;
    color: #333;
    font-size: 14px;
    transition: background-color 0.3s;
}

.tab-button.active {
    background-color: #316cf6;
    color: #fff;
    border-color: #316cf6;
}

.banner-video {
    width: 65%;
}

.slogan {
    animation: slide-in-left 1.5s ease-in-out forwards;
    opacity: 0;
    font-size: 3.2rem;
    color: #444;
    max-width: 80vw;
}

.sub-slogan {
    animation: slide-in-left-far 1.5s ease-in-out 0.2s forwards;
    opacity: 0;
    font-size: 1.2rem;
    margin-top: 15px;
    color: #555;
    max-width: 80vw;
}

.slogan-coming {
    animation: slide-in-left-farthest 1.5s ease-in-out 0.4s forwards;
    opacity: 0;
    font-size: 1.5rem;
    max-width: 80vw;
    color: #282828;
    margin-top: 55px;
}

@keyframes slide-in-left {
    0% {
        transform: translateX(-25%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left-far {
    0% {
        transform: translateX(-30%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left-farthest {
    0% {
        transform: translateX(-35%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scaleUpSm {
    0% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes fade-in {
    from {
        transform: scale(0.85);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.introduction-buttons-container {
    display: flex;
    margin-top: 30px;
    max-width: 80vw;
}

.learn-more-btn:hover {
    background-color: #316cf6d9;
    cursor: pointer;
}

.learn-more-btn {
    animation: scaleUpSm 1.5s ease-in-out 0.4s forwards;
    transform: scale(0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #316cf6;
    color: white;
    width: 235px;
    height: 55px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1em;
}

/*******************/

.waitlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 10vh;
    width: 100%;
}

.waitlist-title {
    color: #444;
    font-size: 1.1rem;
}

.waitlist-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3%;
    height: 7vh;
    width: 50%;
}

.waitlist-input {
    background-color: #f9f9f9;
    color: #444;
    border: 2px solid #316cf6;
    border-radius: 8px;
    height: 40px;
    width: 70%;
    text-indent: 5%;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
}

.waitlist-btn {
    display: flex;
    background-color: #316cf6;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    color: white;
    height: 45px;
    width: 20%;
    font-size: 0.85rem;
    text-decoration: none;
}

/********* Hosts ***********/

.host-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12vh;
}

.host-title {
    color: #316cf6;
    font-weight: bold;
    font-size: 2.2rem;
}

.host-desc {
    color: #444;
    font-size: 1.2rem;
    margin-top: 15px;
    max-width: 45%;
}

.host-slider-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 0 5%;
}

.host-slider {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 35vh;
}

.host-slider-img {
    height: 150px;
}

.host-slider-title {
    color: #316cf6;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
    margin-bottom: 5px;
}

.host-slider-desc {
    color: #444;
    font-size: 1.05rem;
    flex-wrap: wrap;
    width: 70%;
}

.divider {
    border: 1.5px dashed #316cf6;
    width: 60%;
    margin: 60px 0;
}

/********* Renters ***********/

.renter-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
}

.renter-title {
    color: #316cf6;
    font-weight: bold;
    font-size: 2.2rem;
}

.renter-desc {
    color: #444;
    font-size: 1.2rem;
    margin-top: 15px;
    max-width: 45%;
}

.renter-slider-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 0 4%;
}

.renter-slider {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 35vh;
}

.renter-slider-img {
    height: 150px;
}

.renter-slider-title {
    color: #316cf6;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
    margin-bottom: 5px;
}

.renter-slider-desc {
    color: #444;
    font-size: 1.05rem;
    flex-wrap: wrap;
    width: 70%;
}

/*****************/

.waitlist-mid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.waitlist-mid-title {
    color: #444;
    font-size: 1.1rem;
    width: 50%;
}

.waitlist-mid-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3%;
    height: 7vh;
    width: 50%;
}

.waitlist-input-mid {
    background-color: #f9f9f9;
    color: #444;
    border: 2px solid #316cf6;
    border-radius: 8px;
    height: 40px;
    width: 70%;
    text-indent: 5%;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
}

.waitlist-btn-mid {
    display: flex;
    background-color: #316cf6;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    color: white;
    height: 45px;
    width: 20%;
    font-size: 0.85rem;
    text-decoration: none;
}

/********Parking Lot*********/

.parking-lot-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    border-radius: 15px;
}

.parking-lot-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 60vh;
    width: 100%;
    margin: 0 15px;
    margin-bottom: 15px;
    border-radius: 15px;
    background: linear-gradient(90deg, rgba(30, 30, 30, 0.75) 52%, rgba(0, 0, 0, 0) 0%), url('../../assets/parking-lot.jpg');
    background-size: cover;
}

.parking-lot-texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 52%;
    height: 100%;
}

.parking-lot-title {
    font-size: 1.5rem;
    color: #f9e82b;
    margin-bottom: 15px;
}

.parking-lot-text {
    font-size: 1rem;
    color: white;
    margin-bottom: 30px;
    max-width: 70%;
}

.parking-lot-learn {
    display: flex;
    background-color: #316cf6;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: white;
    width: 215px;
    height: 50px;
    font-size: 1rem;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    /* Mobile-specific styles go here */
    .section-banner {
        margin-top: 5vh;
        margin-bottom: 7vh;
        width: 100%;
    }
    .introduction-container {
        width: 90%;
        align-items: center;
    }
    .slogan {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 5px;
    }
    .sub-slogan {
        font-size: 1.2rem;
        text-align: center;
    }
    .slogan-coming {
        font-size: 1.15rem;
        text-align: center;
        min-width: 90vw;
    }
    .introduction-buttons-container {
        justify-content: center;
    }
    .learn-more-btn {
        width: 180px;
        height: 45px;
        font-size: 0.9rem;
    }
    .banner-container {
        margin-top: 20px;
    }
    .banner-video {
        width: 75%;
    }
    .waitlist-title,
    .waitlist-mid-title {
        font-size: 0.8rem;
        width: 90%;
    }
    .waitlist-form,
    .waitlist-mid-form {
        width: 90%;
        height: 6vh;
    }
    .waitlist-input,
    .waitlist-btn,
    .waitlist-mid-input,
    .waitlist-mid-btn {
        font-size: 0.8rem;
    }
    .waitlist-mid {
        margin: 2vh 0;
    }
    .renter-title,
    .host-title {
        font-size: 1.8rem;
    }
    .renter-desc,
    .host-desc {
        max-width: 80%;
        font-size: 1rem;
    }
    .host-container {
        margin-bottom: 5vh;
    }
    .renter-slider-container,
    .host-slider-container {
        flex-direction: column;
        gap: 40px;
    }
    .renter-slider-title,
    .host-slider-title {
        font-size: 1.35rem;
    }
    .renter-slider-desc,
    .host-slider-desc {
        font-size: 1rem;
        padding: 0 30px;
    }
    .parking-lot-container {
        margin-top: 5vh;
    }
    .parking-lot-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 50vh;
        margin: 0 10px;
        margin-bottom: 10px;
        background: linear-gradient(90deg, rgba(30, 30, 30, 0.5) 100%, rgba(0, 0, 0, 0) 0%), url('../../assets/parking-lot.jpg');
        background-size: cover;
    }
    .parking-lot-texts {
        width: 100%;
    }
    .parking-lot-title {
        font-size: 1.3rem;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
    .parking-lot-text {
        font-size: 0.9em;
        max-width: 80%;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
    .parking-lot-learn {
        width: 185px;
        height: 45px;
        font-size: 0.9rem;
    }
}

.return-page-container {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
}

.logo {
    height: 72px;
}

.btn {
    background-color: #316cf6;
    color: white;
    padding: 16px;
    font-size: 1.06rem;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.24);
    transition: transform 0.1s ease-in-out;
}

.btn:active {
    transform: scale(0.95);
}

.background-img {
    width: 600px;
    height: auto;
    opacity: 0.7;
    z-index: -1;
    overflow-x: hidden;
}

/* Mobile Design */
@media screen and (max-width: 768px) {
    .background-img {
        width: 400px;
        overflow-x: hidden;
    }
}

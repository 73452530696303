:root {
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    color-scheme: light dark;
    color: rgba(255, 255, 255, 0.87);
    background-color: white;

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
}

html {
    background-color: '#FFF';
}

body {
    margin: 0;
    display: flex;
    place-items: center;
    min-width: 320px;
    min-height: 100vh;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    background-color: '#FFF';
}

h1 {
    font-size: 3.2em;
    line-height: 1.1;
}

button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
}
/* button:hover {
  border-color: #646cff; */
/* } */
button:focus,
button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
}

/* CHROME DEFAULT FOCUS - Has a yellow outline */
/* Disable default focus outline for all elements */
button:focus,
input:focus,
textarea:focus {
    outline: none;
}

@media (prefers-color-scheme: light) {
    :root {
        color: #213547;
        background-color: #ffffff;
    }
    button {
        background-color: #f9f9f9;
    }
}

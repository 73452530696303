.navbar-container {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    height: 20vh;
    padding: 0 6vw;
    align-items: center;
    justify-content: space-between;
}

.logo-container {
    display: flex;
    height: 100%;
}

.logo-img {
    height: 100px;
    cursor: pointer;
}

.choices-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.nav-link {
    color: #585858;
    font-size: 20;
}

.nav-link:hover {
    color: #316cf6;
}

@media screen and (max-width: 768px) {
    /* Mobile-specific styles go here */
    .navbar-container {
        padding: 0 8vw;
    }
    .logo-img {
        height: 75px;
    }
    .choices-container {
        gap: 30px;
    }
    .nav-link {
        font-size: 15px;
    }
}

#root {
    margin: 0 auto;
    text-align: center;
}

.app {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    background-color: 'white';
}

h1,
h2,
h3,
img,
p {
    margin: 0 0;
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
}
